.recipe-container {
  font-family: Arial, sans-serif;
  width: 60%;
  margin: 0 auto;
}
.recipe-container h1 {
  font-size: 2rem;
  text-align: center;
}
.recipe-container .ingredient-header,
.recipe-container .instruction-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
}
.recipe-container ul,
.recipe-container ol {
  font-size: 1.1rem;
  margin: 0;
  padding: 0;
}
.recipe-container li {
  list-style: none;
  margin: 0.5rem 0;
}
.recipe-container .enjoy {
  font-size: 1.1rem;
  font-style: italic;
  text-align: center;
  margin-top: 2rem;
}
@media (max-width: 768px) {
  .recipe-container {
    width: 90%;
  }
  .recipe-container h1 {
    font-size: 1.5rem;
  }
  .recipe-container .ingredient-header,
  .recipe-container .instruction-header {
    font-size: 1.1rem;
    margin-top: 0.5rem;
  }
  .recipe-container ul,
  .recipe-container ol {
    font-size: 1rem;
  }
  .recipe-container .enjoy {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

