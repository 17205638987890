.recipe-container {
    font-family: Arial, sans-serif;
    width: 60%;
    margin: 0 auto;

    h1 {
        font-size: 2rem;
        text-align: center;
    }

    .ingredient-header,
    .instruction-header {
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 1rem;
    }

    ul,
    ol {
        font-size: 1.1rem;
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
        margin: 0.5rem 0;
    }

    .enjoy {
        font-size: 1.1rem;
        font-style: italic;
        text-align: center;
        margin-top: 2rem;
    }

    @media (max-width: 768px) {
        width: 90%;

        h1 {
            font-size: 1.5rem;
        }

        .ingredient-header,
        .instruction-header {
            font-size: 1.1rem;
            margin-top: 0.5rem;
        }

        ul,
        ol {
            font-size: 1rem;
        }

        .enjoy {
            font-size: 1rem;
            margin-top: 1rem;
        }
    }
}