@import '../../variables';

.buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;

  .button {
    flex-grow: 1;
    text-align: center;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    // adjust this value to change how rounded the corners are
    border-radius: 25px;
    // you might need to adjust this value
    z-index: 1;
    // this line adds the border
    border: 2px solid $primary;

    &.is-selected {
      background-color: $primary;
      color: white;
    }
  }
}