.buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.buttons .button {
  flex-grow: 1;
  text-align: center;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 25px;
  z-index: 1;
  border: 2px solid #343D2A;
}
.buttons .button.is-selected {
  background-color: #343D2A;
  color: white;
}

