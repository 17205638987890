// 2. Setup your Custom Colors
$primary: #343D2A;
// const PRIMARY_BLACK_COLOR = '#292929';
$secondary: #fbfbfb;
$thirdary: #9a9e95;
$quadary: #d6d8d4;
$fifthPrimary: #71776a;
$primaryWhite: #fbfbfb;
$primaryGradientDark: #074c5d;
$primaryLight: #6ec1d6;
$primary-invert: findColorInvert($primary);