// https://maketintsandshades.com/#0D98BA COLOR SHADES

// 1. Import the initial variables
@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";
@import './variables';

// 3. Add new color variables to the color map.
@import "bulma/sass/utilities/derived-variables.sass";
$addColors: (
  "primaryColor":($primary, $primary-invert),
);
$colors: map-merge($colors, $addColors);
// colorize to primary
$tabs-boxed-link-active-background-color: $primary;
$tabs-boxed-link-hover-background-color: $primary;
// $navbar-background-color: $primary;
$navbar-background-color: $primary;
// $navbar-item-hover-background-color: $primary;
$navbar-item-hover-background-color: $primary;
@import 'bulma/bulma';
// import table style
@import './styles/bulma-responsive-tables.scss';


// *dragons beware*: this is associated to view port flex for header and footer
// we can make this better in the future
.home-wrapper {
  display: flex;
  // footer size changes this calculation
  min-height: calc(100vh - 219px);
  flex-direction: column;
  justify-content: space-between;
  // handle background image
  background-image: url('./assets/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.centered {
  /* Magic here */
  height: 73vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-primary-bg {
  background: $primary !important;
}

.is-primary-white-bg {
  background: white !important;
  padding: 8px;
  border-radius: 2px;
}

.is-fifth-bg {
  background: $fifthPrimary !important;
  color: $quadary
}

.is-secondary-bg {
  background-color: $secondary;
}

.is-thirdary-bg {
  background-color: $thirdary;
}

.is-quadary-bg {
  background-color: $quadary;
}

.is-primary-dark-bg {
  background-color: $primaryGradientDark
}

.is-primary-txt {
  color: $primary !important;
}

.is-secondary-txt {
  color: $secondary
}

.is-primary-dark-txt {
  color: $primaryGradientDark !important
}

.is-primary-light-txt {
  color: $primaryLight !important
}

.is-primary-white-background {
  background-color: $primaryWhite !important;
}

.is-white-txt {
  color: $primaryWhite !important;
}

.is-primary-white-button-fill {
  background-color: $primaryWhite;
  border-radius: 10px;
  border-color: $primaryWhite;
}

.is-secondary-border {
  border-radius: 10px;
  border-color: $secondary;

}

.is-secondary-border:hover {
  border-radius: 10px;
  border-color: $primary;
}

.is-secondary-border:focus {
  border-radius: 10px;
  border-color: $primary;
}

.is-secondary-border::placeholder {
  color: black;
  opacity: .7;
}

.is-primary-button-fill {
  background-color: $primary;
  border-radius: 10px;
  border-color: $primary;
}

.is-primary-dark-button-fill {
  background-color: $primaryWhite !important;
  border-radius: 10px;
  border-width: 2px;
  font-weight: 500;
  border-color: $primaryGradientDark !important;
  color: $primaryGradientDark !important;
}

.is-primary-outline-button-fill {
  background-color: $primaryWhite !important;
  border-radius: 10px;
  border-width: 2px;
  font-weight: 500;
  border-color: $primary !important;
  color: $primaryGradientDark !important;
}

.nav-hamburger {
  background: $primary;
  border-color: $primary;
  height: 4.25rem;
}

.is-margin-left-li {
  margin-left: 20px;
}

.is-margin-left-ol {
  margin-left: 20px;
}

.is-tab-active {
  background-color: $secondary !important;
  border-radius: 10px !important;
  border-color: $secondary !important;
  color: $primary !important;
}

.is-active-main-tab {
  background-image: linear-gradient($primaryWhite, $primaryWhite);
  background-size: 95% 4px;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.is-padding-rl-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.is-image-choosen-right-button {
  padding-right: 100px
}

.is-image-choosen-left-button {
  padding-left: 100px
}

@media (max-width: 900px) {
  .is-image-choosen-right-button {
    padding-right: 30px;
    padding-left: 30px;
  }

  .is-image-choosen-left-button {
    padding-left: 30px;
    padding-right: 30px;
  }

  .is-display-none-mobile {
    display: none !important
  }

  .mobile-padding {
    padding-left: 4%;
    padding-right: 4%;
  }
}

.share-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 5px;
}

.manage-margins {
  margin-left: 5px;
  margin-right: 5px;
}

.desktop-container {
  // Default styles for mobile
  width: 100%;

  // Responsive styles for desktop
  @media screen and (min-width: $desktop) {
    // Customize the width as per your requirements
    width: 60% !important;
  }
}

.desktop-button-container {
  width: 75% !important;

  // Responsive styles for desktop
  @media screen and (min-width: $desktop) {
    // Customize the width as per your requirements
    width: 40% !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.center-everything {
  display: flex;
  justify-content: center;
  align-items: center;
}